<template>
  <!--  <h1>Login</h1>
    <button @click="submit">Login</button>
    <button @click="registerRegister">Register</button>-->
  <div class="row justify-content-center">
    <div class="col-md-8">
      <div class="card">
        <div class="card-header">{{ $t('auth.login') }}</div>

        <div class="card-body">
          <form @submit.prevent="submitLogin">

            <div class="form-group row mb-3">
              <label for="email" class="col-md-4 col-form-label text-md-right">{{ $t('form.email') }}</label>

              <div class="col-md-6">
                <input id="email" type="email" class="form-control" name="email" required autocomplete="email"
                       v-model="user.email"/>
              </div>
            </div>

            <div class="form-group row mb-3">
              <label for="password" class="col-md-4 col-form-label text-md-right">{{ $t('form.password') }}</label>

              <div class="col-md-6">
                <input id="password" type="password" class="form-control" name="password" required
                       autocomplete="new-password" v-model="user.password"/>
              </div>
            </div>

            <div class="form-group row mb-0">
              <div class="col-md-6 offset-md-4">
                <button type="submit" class="btn btn-primary">
                  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-if="spin"></span>
                  {{ $t('auth.login') }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "Login",
  data() {
    return {
      spin: false,
      user: {
        email: '',
        password: ''
      }
    }
  },
  methods: {
    ...mapActions({
      'login': 'auth/login'
    }),
    submitLogin() {
      this.spin = true;
      this.login(this.user).then((res) => {
        this.spin = false;
        if (res.code) {
          alert("Error : " + res.code);
        } else {
          location.href = '/';
        }
        // this.$router.replace({name: 'Home'});
      });
    }
  }

}
</script>

<style scoped>

</style>