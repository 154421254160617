<template>
  <h1 v-if="loading">Loading</h1>
  <div v-else>
    <navbar/>
    <div class="container mt-5">
      <router-view/>
    </div>
  </div>
</template>
<script>
import Navbar from "./components/Navbar";
import {onAuthStateChanged} from "firebase/auth";
import {auth} from "./firebase/config";

export default {
  components: {Navbar},
  data() {
    return {
      loading: false
    }
  },
  beforeMount() {
    /*let router = this.$router;
    let route = this.$route;
    onAuthStateChanged(auth, (user) => {
      this.$store.commit('auth/setUser', user);
      if (!user) {
        router.replace({name: 'Login'});
      } else if (user && (route.path === '/login' || route.path === '/register')) {
        // console.log('user >>', user);
        router.replace('/');
      }
      this.loading = false;
    });*/
    this.getLocale();
  },
  methods:{
    getLocale(){
      let locale = localStorage.getItem('locale');
      if (!locale) {
        locale = 'en';
      }
      this.$i18n.locale = locale;
    }
  }
}
</script>