import {createStore} from 'vuex';
import auth from './auth/index';

export default createStore({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        auth
    }
})
