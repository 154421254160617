export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hello i18n !!"])},
  "auth": {
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])}
  },
  "form": {
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail Address"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Password"])},
    "old_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Old Password"])},
    "your_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Password"])},
    "new_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Email"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update"])}
  },
  "navbar": {
    "my_records": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Records"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])}
  },
  "content": {
    "record_audio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Record Audio"])},
    "audios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Audios"])},
    "record_video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Record Video"])},
    "videos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Videos"])},
    "record_screen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Record Screen"])},
    "screens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Screens"])},
    "stop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stop"])},
    "recording": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recording"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])},
    "change_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Email"])},
    "change_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Password"])}
  }
}