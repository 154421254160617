import {createUserWithEmailAndPassword, signOut, signInWithEmailAndPassword, onAuthStateChanged} from "firebase/auth";
import {auth} from "../../firebase/config";

export default {
    namespaced: true,
    state: {
        user: null
    },
    mutations: {
        setUser(state, data) {
            state.user = data;
        }
    },
    getters: {
        authenticated(state) {
            return state.user;
        },
    },
    actions: {
        login({commit}, {email, password}) {
            return signInWithEmailAndPassword(auth, email, password)
                .then((userCredential) => {
                    // Signed in
                    commit('setUser', userCredential.user);
                    return userCredential;
                })
                .catch((error) => {
                    return error;
                });
        },
        register({commit}, {email, password}) {
            return createUserWithEmailAndPassword(auth, email, password)
                .then((userCredential) => {
                    // Signed in
                    commit('setUser', userCredential.user);
                    return userCredential;
                })
                .catch((error) => {
                    return error;
                });
        },
        logout({commit}) {
            return signOut(auth).then(() => {
                commit('setUser', null);
            }).catch((error) => {
                return error;
            });
        },
        getUser({commit}) {
            onAuthStateChanged(auth, (user) => {
                commit('setUser', user);
            });

        }
    },

}