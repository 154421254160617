<template>
  <div class="row" v-for="m in medias">
    <div class="col-md-12 mb-3">
      <div class="row mb-2">
        <div class="col-md-9">
          <h5>{{ m.data().download }}</h5>
        </div>
        <div class="col-md">
          <button class="btn btn-danger float-end" @click="deleteEvent(m.id,m.data().type)">
            <i class="fas fa-trash-alt"></i>
          </button>
        </div>
      </div>
      <audio controls style="width: -webkit-fill-available;" v-if="m.data().type==='audio'">
        <source :src="m.data().link" :type="'audio/'+m.data().ext">
      </audio>
      <video controls style="width: -webkit-fill-available;" v-else>
        <source :src="m.data().link" :type="'video/'+m.data().ext">
      </video>
    </div>
    <hr/>
  </div>
</template>

<script>
export default {
  name: "RecordMedia",
  props: ['medias', 'deleteEvent'],
}
</script>

<style scoped>

</style>