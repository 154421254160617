<template>
  <div class="row">
    <div class="col-md-12 fw-bold">
      <div class="row">
        <div class="col">
          <button type="button" id="recordAudio" class="btn btn-info" :title="$t('content.record_audio')">
            <i class="fas fa-microphone"></i>
            {{ $t('content.record_audio') }}
          </button>
        </div>
        <div class="col">
          <button type="button" id="recordVideo" class="btn btn-info" :title="$t('content.record_video')">
            <i class="fas fa-video"></i>
            {{ $t('content.record_video') }}
          </button>
        </div>
        <div class="col">
          <button type="button" id="recordScreen" class="btn btn-info" :title="$t('content.record_screen')">
            <i class="fas fa-desktop"></i>
            {{ $t('content.record_screen') }}
          </button>
        </div>
        <div class="col">
          <button type="button" class="btn btn-danger" id="stop" :title="$t('content.record_audio')" disabled>
            <i class="fas fa-stop"></i>
            {{ $t('content.stop') }}
          </button>
        </div>
        <div class="col">
          <button type="button" class="btn btn-secondary" v-if="uploadProgress">{{ progress_upload }}</button>
          <a id="download" class="btn btn-success" title="Download" v-show="downloadBtn">
            <i class="fas fa-download"></i>
            {{ $t('content.download') }}
          </a>
        </div>
        <div class="col">
          <div class="row" v-if="stopBtn">
            <div class="col-1">
              <div class="spinner-grow text-danger" role="status">
                <span class="visually-hidden"> {{ $t('content.recording') }}...</span>
              </div>
            </div>
            <div class="col">
              <h4 id="output" class="text-center">00:00:00</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12">
      <div class="ratio ratio-16x9 mt-5">
        <video autoplay muted></video>
      </div>
    </div>
  </div>
</template>

<script>
import {ref, uploadBytesResumable, getDownloadURL, up} from "firebase/storage";
import {db, storage} from "../firebase/config";
import {mapGetters} from "vuex";
import {addDoc, collection} from "firebase/firestore";

export default {
  name: 'Home',
  data() {
    return {
      stopBtn: false,
      uploadProgress: false,
      downloadBtn: false,
      progress_upload: ''
    }
  },
  computed: {
    ...mapGetters({
      'authenticated': 'auth/authenticated'
    }),
  },
  mounted() {

    let time = 0;
    let running = 0;

    let shouldStop = false;
    let stopped = false;
    const videoElement = document.getElementsByTagName("video")[0];
    // const downloadLink = document.getElementById('download');
    const stopButton = document.getElementById('stop');
    const recordScreenBtn = document.getElementById('recordScreen');
    const recordAudioBtn = document.getElementById('recordAudio');
    const recordVideoBtn = document.getElementById('recordVideo');

    let downloadLink = {};
    let self = this;

    function startRecord() {
      self.stopBtn = true;
      self.uploadProgress = false;
      self.downloadBtn = false;
      increment();
      // console.log("startRecord");
    }

    function stopRecord() {
      self.stopBtn = false;
      // console.log("stopRecord");
    }

    const audioRecordConstraints = {
      echoCancellation: true
    }

    stopButton.addEventListener('click', function () {
      shouldStop = true;
    });

    const handleRecord = function ({stream, mimeType, ext, type}) {
      startRecord()
      let recordedChunks = [];
      stopped = false;
      const mediaRecorder = new MediaRecorder(stream);

      mediaRecorder.ondataavailable = function (e) {
        if (e.data.size > 0) {
          recordedChunks.push(e.data);
        }

        if (shouldStop === true && stopped === false) {
          mediaRecorder.stop();
          stopped = true;
        }
      };

      mediaRecorder.onstop = function () {
        self.uploadProgress = true;
        const blob = new Blob(recordedChunks, {
          type: mimeType
        });
        recordedChunks = []
        const filename = window.prompt('Enter file name');
        downloadLink.href = URL.createObjectURL(blob);
        downloadLink.blob = blob;
        downloadLink.download = `${filename || 'recording'}.${ext}`;

        stopRecord();
        videoElement.srcObject = null;
        const metadata = {
          contentType: mimeType
        };

        /**/
        // console.log(downloadLink);
        self.downloadBtn = true;
        document.getElementById('download').href = downloadLink.href;
        document.getElementById('download').blob = downloadLink.blob;
        document.getElementById('download').download = downloadLink.download;
        /**/

        // Upload file and metadata to the object 'images/mountains.jpg'
        const storageRef = ref(storage, `${self.authenticated.uid}/${downloadLink.download}`);
        const uploadTask = uploadBytesResumable(storageRef, downloadLink.blob, metadata);

        // Listen for state changes, errors, and completion of the upload.
        uploadTask.on('state_changed',
            (snapshot) => {
              // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
              const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              self.progress_upload = 'Upload is ' + progress.toFixed(2) + '% done';
              // console.log('Upload is ' + progress + '% done');
              switch (snapshot.state) {
                case 'paused':
                  // console.log('Upload is paused');
                  break;
                case 'running':
                  // console.log('Upload is running');
                  break;
              }
            },
            (error) => {
              // A full list of error codes is available at
              // https://firebase.google.com/docs/storage/web/handle-errors
              switch (error.code) {
                case 'storage/unauthorized':
                  // User doesn't have permission to access the object
                  break;
                case 'storage/canceled':
                  // User canceled the upload
                  break;

                  // ...

                case 'storage/unknown':
                  // Unknown error occurred, inspect error.serverResponse
                  break;
              }
            },
            () => {
              // Upload completed successfully, now we can get the download URL
              // reset();
              self.uploadProgress = false;
              // self.downloadBtn = true;
              getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                // downloadLink.href = downloadURL;
                // downloadLink.download = downloadURL;
                // document.getElementById('download').href = downloadLink.href
                // document.getElementById('download').blob = downloadLink.blob
                // document.getElementById('download').download = downloadLink.download

                // add to Firestore
                addDoc(collection(db, `${self.authenticated.uid}/`), {
                  href: downloadLink.href,
                  // blob: downloadLink.blob,
                  download: downloadLink.download,
                  link: downloadURL,
                  type,
                  ext,
                  deleted: false
                });
                // console.log("Document written with ID: ", docRef.id);
                // console.log('File available at', downloadURL);
              });
            }
        );
      };

      mediaRecorder.start(200);
    };

    async function recordAudio() {
      const mimeType = 'audio/mp3';
      shouldStop = false;
      const stream = await navigator.mediaDevices.getUserMedia({audio: audioRecordConstraints});
      handleRecord({stream, mimeType, ext: 'mp3', type: 'audio'})
    }

    async function recordVideo() {
      const mimeType = 'video/webm;codecs=h264';
      shouldStop = false;
      const constraints = {
        audio: {
          "echoCancellation": true
        },
        video: {
          "width": {
            "min": 640,
            "max": 1024
          },
          "height": {
            "min": 480,
            "max": 768
          }
        }
      };
      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      videoElement.srcObject = stream;
      handleRecord({stream, mimeType, ext: 'mp4', type: 'video'})
    }

    async function recordScreen() {
      const mimeType = 'video/webm;codecs=h264';
      shouldStop = false;
      const constraints = {
        video: {
          cursor: 'motion'
        }
      };
      if (!(navigator.mediaDevices && navigator.mediaDevices.getDisplayMedia)) {
        return window.alert('Screen Record not supported!')
      }
      let stream = null;
      const displayStream = await navigator.mediaDevices.getDisplayMedia({
        video: {mediaSource: "screen"},
        audio: {'echoCancellation': true}
      });
      // const stream = await navigator. mediaDevices. getDisplayMedia({ video: { mediaSource: "screen" } });
      if (window.confirm("Record audio with screen?")) {
        const audioContext = new AudioContext();

        const voiceStream = await navigator.mediaDevices.getUserMedia({
          audio: {
            'echoCancellation': true,
            'noiseSuppression': true
          }, video: false
        });
        const userAudio = audioContext.createMediaStreamSource(voiceStream);

        const audioDestination = audioContext.createMediaStreamDestination();
        userAudio.connect(audioDestination);

        if (displayStream.getAudioTracks().length > 0) {
          const displayAudio = audioContext.createMediaStreamSource(displayStream);
          displayAudio.connect(audioDestination);
        }

        const tracks = [...displayStream.getVideoTracks(), ...audioDestination.stream.getTracks()]
        stream = new MediaStream(tracks);
        handleRecord({stream, mimeType, ext: 'mp4', type: 'screen'})
      } else {
        stream = displayStream;
        handleRecord({stream, mimeType, ext: 'mp4', type: 'screen'});
      }
      videoElement.srcObject = stream;
    }

    recordScreenBtn.addEventListener('click', function () {
      recordScreen();
    });

    recordAudioBtn.addEventListener('click', function () {
      recordAudio();
    });

    recordVideoBtn.addEventListener('click', function () {
      recordVideo();
    });

    function increment() {
      // console.log("increment")
      // if (running === 1) {
      setTimeout(function () {
        time++;
        var mins = Math.floor(time / 10 / 60);
        var secs = Math.floor(time / 10 % 60);
        var tenths = time % 10;
        if (mins < 10) {
          mins = "0" + mins;
        }
        if (secs < 10) {
          secs = "0" + secs;
        }
        document.getElementById("output").innerHTML = mins + ":" + secs + ":" + "0" + tenths;
        increment();
      }, 100);
      // }
    }

    function reset() {
      running = 0;
      time = 0;
      // document.getElementById("startPause").innerHTML = "START";
      document.getElementById("output").innerHTML = "00:00:00";
    }

    function isMobileDevice() {
      let check = false;
      (function (a) {
        if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) check = true;
      })(navigator.userAgent || navigator.vendor || window.opera);
      // console.log(check);
      return check;
    }
  },
  watch: {
    stopBtn(newVal, oldVal) {
      if (newVal === false) {
        document.getElementById('stop').setAttribute('disabled', 'true');
        document.getElementById('recordAudio').removeAttribute('disabled');
        document.getElementById('recordVideo').removeAttribute('disabled');
        document.getElementById('recordScreen').removeAttribute('disabled');
      } else {
        document.getElementById('stop').removeAttribute('disabled');
        document.getElementById('recordAudio').setAttribute('disabled', 'true');
        document.getElementById('recordVideo').setAttribute('disabled', 'true');
        document.getElementById('recordScreen').setAttribute('disabled', 'true');
      }
    }
  }
}
</script>
