import {createRouter, createWebHistory} from 'vue-router';
import store from '@/store';
import Home from '../views/Home.vue';
import MyRecords from "../views/MyRecords";
import Settings from "../views/Settings";
import Register from "../views/Register";
import Login from "../views/Login";


const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        beforeEnter: isAuth
    },
    {
        path: '/my-records',
        name: 'MyRecords',
        component: MyRecords,
        beforeEnter: isAuth
    },
    {
        path: '/settings',
        name: 'Settings',
        component: Settings,
        beforeEnter: isAuth
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        beforeEnter: isNotAuth
    },
    {
        path: '/register',
        name: 'Register',
        component: Register,
        beforeEnter: isNotAuth
    },
];

function isNotAuth(to, from, next) {
    if (store.getters['auth/authenticated']) {
        return next({name: 'Home'});
    }
    next();
}

function isAuth(to, from, next) {
    if (!store.getters['auth/authenticated']) {
        return next({name: 'Login'});
    }
    next();
}

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    linkActiveClass: 'active',
    linkExactActiveClass: 'active'
})

export default router
