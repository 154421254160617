<template>
  <div class="accordion mb-2" :id="'accordion-'+id">
    <div class="accordion-item">
      <h2 class="accordion-header" :id="'heading-'+id">
        <button class="accordion-button" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapse-'+id"
                aria-expanded="true" :aria-controls="'collapse-'+id">
          {{ $t(header) }}
        </button>
      </h2>
      <div :id="'collapse-'+id" class="accordion-collapse collapse show" :aria-labelledby="'heading-'+id"
           :data-bs-parent="'#accordion-'+id">
        <div class="accordion-body">
          <slot/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Accordion",
  props: ['id', 'header']
}
</script>

<style scoped>

</style>