import {createApp} from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min';

import '@fortawesome/fontawesome-free/css/all.min.css';
import {onAuthStateChanged} from "firebase/auth";
import {auth} from "./firebase/config";
import i18n from './i18n'

const app = createApp(App).use(i18n);


onAuthStateChanged(auth, (user) => {
    store.commit('auth/setUser', user);
    app.use(store).use(router).mount('#app');
});
// app.use(store).use(router).mount('#app');
