export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hello i18n !!"])},
  "auth": {
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S'inscrire"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se déconnecter"])}
  },
  "form": {
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse E-mail"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
    "new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau Mot de passe"])},
    "your_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre Mot de passe"])},
    "old_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ancien Mot de passe"])},
    "new_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvel E-mail"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre à jour"])}
  },
  "navbar": {
    "my_records": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes Enregistrements"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres"])}
  },
  "content": {
    "record_audio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer Audio"])},
    "audios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Audios"])},
    "record_video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer Video"])},
    "videos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Videos"])},
    "record_screen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer l'Ecran"])},
    "screens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ecrans"])},
    "stop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arrêter"])},
    "recording": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrement"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger"])},
    "change_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier Email"])},
    "change_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier Mot de passe"])}
  }
}