<template>
  <div class="row">
    <div class="col-md-4">
      <Accordion id="audios" header="content.audios" v-if="audios">
        <spinner v-if="audios_spinner"/>
        <record-media :medias="audiosData" :delete-event="deleteMedia" v-else/>
      </Accordion>
    </div>
    <div class="col-md-4">
      <Accordion id="videos" header="content.videos" v-if="videos">
        <spinner v-if="videos_spinner"/>
        <record-media :medias="videosData" :delete-event="deleteMedia" v-else/>
      </Accordion>
    </div>
    <div class="col-md-4">
      <Accordion id="screens" header="content.screens" v-if="screens">
        <spinner v-if="screens_spinner"/>
        <record-media :medias="screensData" :delete-event="deleteMedia" v-else/>
      </Accordion>
    </div>
  </div>
  <!--
    <div class="fw-bold">
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="checkbox" id="audios" value="Audios" v-model="audios"
               @change="checkType">
        <label class="form-check-label" for="audios">Audios</label>
      </div>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="checkbox" id="videos" value="Videos" v-model="videos" @change="checkType">
        <label class="form-check-label" for="videos">Video</label>
      </div>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="checkbox" id="screens" value="Screens" v-model="screens"
               @change="checkType">
        <label class="form-check-label" for="screens">Screen</label>
      </div>
    </div>

    <div class="mt-5">
      <Accordion id="audios" header="Audios" v-if="audios">
        <div class="row">
          <div class="col-md-4">
            <audio v-for="a in audiosData" controls style="width: -webkit-fill-available;">
              <source :src="a.link" :type="'audio/'+a.ext">
            </audio>
          </div>
        </div>
      </Accordion>
      <Accordion id="videos" header="Videos" v-if="videos">
        <div class="row">
          <div class="col-md-4">
            <video v-for="v in videosData" controls style="width: -webkit-fill-available;">
              <source :src="v.link" :type="'video/'+v.ext">
            </video>
          </div>
        </div>
      </Accordion>
      <Accordion id="screens" header="Screens" v-if="screens">
        <div class="row">
          <div class="col-md-4">
            <video v-for="s in screensData" controls style="width: -webkit-fill-available;">
              <source :src="s.link" :type="'video/'+s.ext">
            </video>
          </div>
        </div>
      </Accordion>
    </div>
  -->

</template>

<script>
import Accordion from "../components/Accordion";
import {db} from "../firebase/config";
import {mapGetters} from "vuex";
import {collection, getDocs, where, query, updateDoc, doc} from "firebase/firestore";
import RecordMedia from "../components/RecordMedia";
import Spinner from "../components/Spinner";

export default {
  name: "MyRecords",
  components: {Spinner, RecordMedia, Accordion},
  data() {
    return {
      audios: true,
      videos: true,
      screens: true,
      audiosData: [],
      videosData: [],
      screensData: [],
      audios_spinner: true,
      videos_spinner: true,
      screens_spinner: true,
    }
  },
  computed: {
    ...mapGetters({
      'authenticated': 'auth/authenticated'
    }),
  },
  mounted() {
    // this.getAllFiles();
    this.getMedia("audio");
    this.getMedia("video");
    this.getMedia("screen");
  },
  methods: {
    async getAllFiles() {
      let self = this;
      const querySnapshot = await getDocs(collection(db, `${this.authenticated.uid}`));
      querySnapshot.forEach((doc) => {
        // console.log(`${doc.id}`);
        // console.log(doc.data());
        let type = doc.data().type;
        if (type === "audio") {
          this.audiosData.push(doc);
        } else if (type === "video") {
          this.videosData.push(doc);
        } else if (type === "screen") {
          this.screensData.push(doc);
        }
      });
      // console.log(this.audiosData)
      // console.log(this.videosData)
      // console.log(this.screensData)
    },
    getMedia(type) {
      let self = this;
      self[type + 'sData'] = [];
      self[type + 's_spinner'] = true;
      const q = query(collection(db, `${this.authenticated.uid}`), where("type", "==", type), where("deleted", "==", false));
      getDocs(q).then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          self[type + 'sData'].push(doc);
        });
        self[type + 's_spinner'] = false;
      });
    },
    async deleteMedia(id, type) {
      if(confirm('Confirm delete')){
        await updateDoc(doc(db, `${this.authenticated.uid}`, `${id}`), {
          "deleted": true,
        });
        this.getMedia(type);
      }
    },
    checkType(e) {
      this[e.target.id] = !!this[e.target.id];
    }
  }
}
</script>

<style scoped>

</style>