<template>
  <div class="form-floating mb-3">
    <input
        :id="id"
        :type="type"
        class="form-control"
        v-model="form[id]"
        :name="id"
        :placeholder="id"
    />
    <label :for="id">{{ $t(label) }}</label>
  </div>
</template>

<script>
export default {
  name: "LabeledInput",
  props: ['id', 'type', 'form', 'label']
}
</script>

<style scoped>

</style>
