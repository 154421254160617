<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-white shadow-sm fw-bold">
    <div class="container">
      <router-link class="navbar-brand" to="/">Recordess</router-link>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0 fw-bold">
          <li class="nav-item">
            <router-link class="nav-link" aria-current="page" to="/my-records" v-if="authenticated">
              {{ $t('navbar.my_records') }}
            </router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" aria-current="page" to="/settings" v-if="authenticated">
              {{ $t('navbar.settings') }}
            </router-link>
          </li>
        </ul>

        <ul class="navbar-nav ml-auto mb-2 mb-lg-0">
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown"
               aria-expanded="false">
              {{ locale.toUpperCase() }}
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <li style="cursor: pointer">
                <span class="dropdown-item" v-for="a in availableLocales" @click="setLocale(a)">
                  {{ a.toUpperCase() }}
                </span>
              </li>
            </ul>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" aria-current="page" to="/login" v-if="!authenticated">{{
                $t('auth.login')
              }}
            </router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" aria-current="page" to="/register" v-if="!authenticated">
              {{ $t('auth.register') }}
            </router-link>
          </li>
          <li class="nav-item">
            <span class="nav-link" aria-current="page" @click="submit" v-if="authenticated" style="cursor: pointer">
              <i class="fas fa-sign-out-alt"></i>
              {{ $t('auth.logout') }}
            </span>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "Navbar",
  data() {
    return {
      availableLocales: this.$i18n.availableLocales,
      locale: this.$i18n.locale,
    }
  },
  computed: {
    ...mapGetters({
      'authenticated': 'auth/authenticated'
    }),
  },
  mounted() {
    // console.log(this.availableLocales);
    this.showLocales();
  },
  methods: {
    ...mapActions({
      'logout': 'auth/logout'
    }),
    submit() {
      this.logout().then(res => {
        if (res === undefined)
            // this.$router.push({name: 'Login'});
          location.href = '/login';
      });
    },
    setLocale(locale) {
      this.$i18n.locale = locale;
      this.locale = locale;
      localStorage.setItem('locale', locale);
      this.showLocales();
    },
    showLocales() {
      this.availableLocales = this.$i18n.availableLocales
      let index = this.availableLocales.indexOf(this.locale);
      this.availableLocales.splice(index, 1);
    }
  }
}
</script>

<style scoped>

</style>